import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { CamposAlterarSenha } from 'components/DialogoAlterarSenha/types';
import { useAuth, useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { UseAlterarSenhaResposta } from './types';

export function useAlterarSenha(): UseAlterarSenhaResposta {
  const criarMensagem = useGerenciadorDeMensagens();
  const { login } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const alterarSenha = useCallback(async (campos: CamposAlterarSenha) => {
    try {
      setLoading(true);

      const { data: result } = await servicoBase.post(
        '/v1/autenticacoes/senhas',
        { login, senha: campos.senhaAtual, nova_senha: campos.novaSenha },
      );

      if (result)
        criarMensagem({
          titulo: 'Senha alterada com sucesso!',
          texto: '',
          icone: 'fa-regular fa-key',
          tipo: 'sucesso',
        });

      return result || null;
    } catch (error: any) {
      criarMensagem({
        titulo: 'Senha atual incorreta',
        texto:
          'Por favor, verifique se você digitou sua senha corretamente e tente novamente.',
        tipo: 'erro',
      });
      setErro(error as AxiosError);
    } finally {
      setLoading(false);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [alterarSenha, { loading, erro }];
}
