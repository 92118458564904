import 'digix-ui/ativos/sass/temas/horus.scss';
import ContainerDeMensagensFlutuantes from '@bit/digix.digixui.mensagens-flutuantes';

import { routesConfig } from 'config';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { RouterCreator } from 'router';
import { useAuth } from '../hooks';

export function AppContext(): JSX.Element {
  const { ehAdmin } = useAuth();
  const validadorDePermissoes: any = useCallback(
    (permissoes: string[]) => {
      if (isEmpty(permissoes)) {
        return true;
      }

      return permissoes.includes('admin') ? ehAdmin : true;
    },
    [ehAdmin],
  );
  return (
    <>
      <ContainerDeMensagensFlutuantes />
      <RouterCreator
        routesConfig={routesConfig}
        redirectPath="/app/documentos"
        fallbackRedirectPath="/"
        permissionValidator={validadorDePermissoes}
      />
    </>
  );
}
