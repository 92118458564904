import { useAlterarSenha } from 'services/login';
import { CamposAlterarSenha } from './types';

export function useBloc(fechar: () => void) {
  const [alterarSenha, { loading }] = useAlterarSenha();

  const handleSubmit = async (campos: CamposAlterarSenha) => {
    const sucesso = await alterarSenha(campos);
    if (sucesso) fechar();
  };

  return {
    handleSubmit,
    loading,
  };
}
