import { createContext } from 'react';

import { Login } from 'models/login';

export interface AuthState {
  id?: string;
  nome?: string;
  token?: string;
  login?: string;
  setor?: string;
  podeAssinar?: boolean;
  ehAdmin?: boolean;
  comunicados?: boolean;
  email?: string;
  permissaoParaBaixarArquivos?: boolean;
}

export type GetTemPermissaoFnOpcoes = {
  temQualquerPermissao?: boolean;
  temTodasAsPermissoes?: boolean;
};

export type GetHasPermissionsFn<T = any> = (
  permissions: T[],
  options?: GetTemPermissaoFnOpcoes,
) => boolean;

export interface AuthContextData {
  id?: string;
  token?: string;
  papelZeroToken?: string;
  nome?: string;
  login?: string;
  ehAdmin?: boolean;
  podeAssinar?: boolean;
  dispositivoId: string;
  lotacao?: number;
  logar(login: Login): void;
  deslogar(): void;
  comunicados?: boolean;
  email?: string;
  permissaoParaBaixarArquivos?: boolean;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);
