import * as Yup from 'yup';

export const alterarSenhaSchema = Yup.object().shape({
  senhaAtual: Yup.string().required('Este campo é obrigatório'),
  novaSenha: Yup.string()
    .required('Este campo é obrigatório')
    .min(8, 'A nova senha precisa ter pelo menos 8 caracteres'),
  repetirNovaSenha: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf([Yup.ref('novaSenha')], 'As senhas precisam ser iguais'),
});
