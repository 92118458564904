/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError } from 'axios';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';

const servicoBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const token = localStorage.getItem('token');

servicoBase.defaults.headers.common.Authorization = token
  ? `Bearer ${token}`
  : '';

servicoBase.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    switch (error?.response?.status) {
      case 401: {
        if (window?.deslogar && token) {
          window.deslogar();
          GerenciadorDeMensagem.criarMensagem({
            titulo: 'Erro',
            tipo: 'erro',
            texto: 'Login expirado, entre novamente.',
          });
          break;
        }

        return Promise.reject(error);
      }
      default:
        return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

export { servicoBase };
