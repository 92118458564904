export const pageTransition = {
  ease: 'easeInOut',
  duration: 0.4,
};

const bottomToUpVariants = {
  initial: {
    opacity: 0,
    y: 100,
    transition: pageTransition,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: pageTransition,
  },
  exit: {
    opacity: 0,
    y: -100,
    transition: pageTransition,
  },
};

const upToBottomVariants = {
  initial: {
    opacity: 0,
    y: 100,
    transition: pageTransition,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: pageTransition,
  },
  exit: {
    opacity: 0,
    y: -100,
    transition: pageTransition,
  },
};

const rightToLeftVariants = {
  initial: {
    opacity: 0,
    x: 100,
    transition: pageTransition,
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: pageTransition,
  },
  exit: {
    opacity: 0,
    x: -100,
    transition: pageTransition,
  },
};

const leftToRightVariants = {
  initial: {
    opacity: 0,
    x: -100,
    transition: pageTransition,
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: pageTransition,
  },
  exit: {
    opacity: 0,
    x: 100,
    transition: pageTransition,
  },
};

export const animation = {
  leftToRight: leftToRightVariants,
  rightToLeft: rightToLeftVariants,
  bottomToUp: bottomToUpVariants,
  upToBottom: upToBottomVariants,
};
