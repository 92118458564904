export function cpfValidator(cpfWithMask: string): boolean {
  if (!cpfWithMask) {
    return false;
  }
  const cpf = cpfWithMask.replace(/\D/g, '');
  let sumFirstVerificationDigit = 0;
  let sumSecondVerificationDigit = 0;
  let rest: number;

  if (cpf.length !== 11) {
    return false;
  }

  const arrTest = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  let invalidPattern = false;

  arrTest.forEach(testItem => {
    const strTest = Array(11).fill(testItem).join('');

    if (strTest === cpf) {
      invalidPattern = true;
    }
  });

  if (invalidPattern) {
    return false;
  }

  const splittedCpf = cpf.split('');

  splittedCpf.forEach((digit: string, index) => {
    const value1 = Number(digit) * (11 - (index + 1));
    const value2 = Number(digit) * (12 - (index + 1));

    if (index < 9) {
      sumFirstVerificationDigit += value1;
    }

    if (index < 10) {
      sumSecondVerificationDigit += value2;
    }
  });

  rest = (sumFirstVerificationDigit * 10) % 11;
  let turnRestToZero = rest === 10 || rest === 11;

  if (turnRestToZero) {
    rest = 0;
  }

  if (rest !== Number(cpf.substring(9, 10))) {
    return false;
  }

  rest = (sumSecondVerificationDigit * 10) % 11;
  turnRestToZero = rest === 10 || rest === 11;

  if (turnRestToZero) {
    rest = 0;
  }

  if (rest !== Number(cpf.substring(10, 11))) {
    return false;
  }

  return true;
}
