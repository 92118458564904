import { useMemo } from 'react';

import { motion } from 'framer-motion';

import { animation, pageTransition } from './config';
import { MotionWrapperProps } from './types';

export type AnimationType = keyof typeof animation;

export function MotionWrapper({
  children,
  loading = false,
  animationType = 'leftToRight',
}: MotionWrapperProps): JSX.Element {
  const memoVariants = useMemo(() => {
    return animation[animationType] ?? animation.upToBottom;
  }, [animationType]);

  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={memoVariants}
      transition={pageTransition}
    >
      {!loading && children}
    </motion.div>
  );
}
