import { ReactNode } from 'react';

import { AnimationType } from '.';
import { MotionWrapper } from './MotionWrapper';

interface ProtectedPageWrapperProps {
  children: ReactNode;
  overflowHiddenMotionWrapper?: boolean;
  animationType?: AnimationType;
}

export function ProtectedPageWrapper({
  children,
  overflowHiddenMotionWrapper,
  animationType = 'leftToRight',
}: ProtectedPageWrapperProps): JSX.Element {
  return (
    <MotionWrapper
      overflowHidden={overflowHiddenMotionWrapper}
      animationType={animationType}
    >
      {children}
    </MotionWrapper>
  );
}
