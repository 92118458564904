import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { CamposEsqueciSenha } from 'pages/Home/components/EsqueciSenha/types';
import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { UseEsqueciSenhaResposta } from './types';

export function useEsqueciSenha(): UseEsqueciSenhaResposta {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const esqueciSenha = useCallback(
    async (campos: CamposEsqueciSenha) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.post(
          '/v1/autenticacoes/esquecisenha',
          campos,
        );

        if (result)
          criarMensagem({
            titulo: 'E-mail enviado com sucesso',
            texto: '',
            tipo: 'sucesso',
          });

        return result || null;
      } catch (error: any) {
        criarMensagem({
          titulo: 'Não há cadastro com o e-mail informado',
          texto: 'Tente novamente mais tarde.',
          tipo: 'erro',
        });
        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [criarMensagem],
  );
  return [esqueciSenha, { loading, erro }];
}
