export function formatarPaginas(paginas: number[]): string {
  const paginasFormatadas = [];

  if (paginas.length === 0) return '';

  let paginaInicial = paginas[0];
  let paginaFinal = paginas[0];

  for (let i = 1; i < paginas.length; i += 1) {
    if (paginas[i] === paginaFinal + 1) {
      // Page sequence continues
      paginaFinal = paginas[i];
    } else {
      // Page sequence breaks, add the formatted range
      paginasFormatadas.push(
        paginaInicial === paginaFinal
          ? paginaInicial.toString()
          : `${paginaInicial}-${paginaFinal}`,
      );
      paginaInicial = paginas[i];
      paginaFinal = paginas[i];
    }
  }

  // Add the last formatted range
  paginasFormatadas.push(
    paginaInicial === paginaFinal
      ? paginaInicial.toString()
      : `${paginaInicial}-${paginaFinal}`,
  );

  return paginasFormatadas.join(', ');
}
