import { servicoBase } from '../servico-base';

export async function termoDeUso(): Promise<void> {
  try {
    const { data } = await servicoBase.get<{
      url: string;
    }>(`/v1/termos/termodeuso`);

    const ancora = document.createElement('a');
    ancora.href = data.url;
    ancora.target = '_blank';
    ancora.click();
    document.body.removeChild(ancora);
  } catch (e) {
    console.info('error no termo de uso', e);
  }
}

export async function termoDePrivacidade(): Promise<void> {
  try {
    const { data } = await servicoBase.get<{
      url: string;
    }>(`/v1/termos/termodeprivacidade`);
    const ancora = document.createElement('a');
    ancora.href = data.url;
    ancora.target = '_blank';
    ancora.click();
    document.body.removeChild(ancora);
  } catch (e) {
    console.info('error no termo de privacidade', e);
  }
}

export async function verificarSeAceitouTermos(
  login: string,
): Promise<boolean> {
  try {
    const { data: result } = await servicoBase.get<boolean>(
      `/v1/termos/verificartermodeuso`,
      {
        params: {
          login,
          nomeProduto: process.env.REACT_APP_PRIVACY_POLICY_ENV,
        },
      },
    );

    return result || false;
  } catch {
    return false;
  }
}

export async function getHashedUrlTermos(login: string): Promise<string> {
  try {
    const { data: result } = await servicoBase.get<{
      url: string;
    }>(`/v1/termos/solicitaraceite`, {
      params: {
        login,
        nomeProduto: process.env.REACT_APP_PRIVACY_POLICY_ENV,
        returnUrl: `${window.location.origin}/app/documentos`,
      },
    });

    return result?.url || '';
  } catch {
    return '';
  }
}
