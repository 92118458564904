/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteConfigItem } from 'models';

function generateRouteID(key: string): string {
  const prefix = process.env.REACT_APP_STORAGE_PREFIX || '@horus-digix';
  return `${prefix}:${key}`;
}

export const routesConfig: RouteConfigItem[] = [
  {
    id: generateRouteID('home'),
    path: '/',
    elementPath: 'Home',
    isPrivate: false,
    redirectPath: '/app/documentos',
  },
  {
    id: generateRouteID('app'),
    path: '/app',
    isPrivate: true,
    children: [
      {
        id: generateRouteID('documentos'),
        path: '/documentos',
        elementPath: 'Documentos',
        routeTitle: 'Documentos',
        isPrivate: true,
        redirectPath: '/',
      },
      {
        id: generateRouteID('arquivo'),
        path: '/arquivo/:id',
        elementPath: 'Arquivo',
        routeTitle: 'Arquivo',
        isPrivate: true,
        redirectPath: '/',
      },
      {
        id: generateRouteID('configuracoes'),
        path: '/configuracoes',
        elementPath: 'Configuracoes',
        routeTitle: 'Configurações',
        isPrivate: true,
        redirectPath: '/',
        permissions: ['admin'],
      },
    ],
  },
];
