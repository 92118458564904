import { RouteConfigItem } from 'models';

import { GetNormalizedRoutesParams, RecursiveRouterParams } from './types';

function routesNormalizer(routes: RouteConfigItem[]): RouteConfigItem[] {
  let formattedRoutes: RouteConfigItem[] = routes
    .flatMap(route => {
      const { children, ...rest } = route;

      return [rest, ...[children || []]];
    })
    .flat();

  formattedRoutes = formattedRoutes.reduce(
    (accumulator: RouteConfigItem[], route) => {
      const { children, ...rest } = route;
      const routeChildren = children
        ? routesNormalizer([rest, ...children])
        : [rest];

      accumulator = [...accumulator, ...routeChildren];

      return accumulator;
    },
    [],
  );

  return formattedRoutes;
}

export function recursiveRouter({
  routes,
  permissionValidator,
  basePath,
  context,
  sortByPathLength = true,
}: RecursiveRouterParams): RouteConfigItem[] {
  let splittedRoutes: RouteConfigItem[] = basePath
    ? routes.map(route => ({
        ...route,
        path: `${basePath}${route.path}`,
        ...(context ? { context } : {}),
      }))
    : [...routes];
  splittedRoutes = splittedRoutes
    .filter(route =>
      permissionValidator
        ? permissionValidator(
            route?.permissions || [],
            route?.permissionValidatorOptions,
          )
        : true,
    )
    .map(route =>
      route?.children
        ? {
            ...route,
            children: recursiveRouter({
              routes: route.children,
              permissionValidator: permissionValidator
                ? permissions => permissionValidator(permissions)
                : undefined,
              basePath: route.path,
              context: route.context,
              sortByPathLength,
            }),
          }
        : route,
    );

  if (sortByPathLength) {
    return splittedRoutes.sort((a, b) => {
      if (a.path.length > b.path.length) {
        return -1;
      }

      if (a.path.length < b.path.length) {
        return 1;
      }

      return 0;
    });
  }

  return splittedRoutes;
}

export function getNormalizedRoutes({
  routes,
  order = 'asc',
  orderBy = 'path',
  removeNoRenderRoutes = false,
  filter,
}: GetNormalizedRoutesParams): RouteConfigItem[] {
  const decorattedRoutes = recursiveRouter({ routes });
  let normalizedRoutes = routesNormalizer(decorattedRoutes);

  normalizedRoutes = normalizedRoutes.sort((routeA, routeB) => {
    if (routeA[orderBy] > routeB[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }

    if (routeA[orderBy] < routeB[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }

    return 0;
  });

  if (removeNoRenderRoutes) {
    normalizedRoutes = normalizedRoutes.filter(
      route => !!(route?.element || route?.elementPath),
    );
  }

  if (filter) {
    normalizedRoutes = normalizedRoutes.filter(
      route =>
        (filter?.isPrivate !== undefined
          ? route.isPrivate === filter.isPrivate
          : true) &&
        (filter?.permissionValidator
          ? filter.permissionValidator(
              route?.permissions || [],
              route?.permissionValidatorOptionsTransform
                ? route.permissionValidatorOptionsTransform(
                    route?.permissionValidatorOptions,
                  )
                : route?.permissionValidatorOptions,
            )
          : true),
    );
  }

  return normalizedRoutes;
}
