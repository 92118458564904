import { useState, useCallback } from 'react';

import { AxiosError } from 'axios';

import { CamposLogin } from 'pages/Home/types';
import { Login } from 'models/login';
import { useGerenciadorDeMensagens } from 'hooks';
import { servicoBase } from 'services/servico-base';
import { UseLoginResposta } from './types';

export function useLogin(): UseLoginResposta {
  const criarMensagem = useGerenciadorDeMensagens();

  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<AxiosError | null>(null);

  const login = useCallback(
    async (campos: CamposLogin) => {
      try {
        setLoading(true);

        const { data: result } = await servicoBase.post<Login | null>(
          '/v1/autenticacoes/login',
          campos,
        );

        return result || null;
      } catch (error: any) {
        const descricaoDoErro =
          error?.response?.data?.detail ||
          'Ocorreu um erro ao efetuar o login.';
        criarMensagem({
          titulo: 'Aconteceu um erro',
          texto: descricaoDoErro,
          tipo: 'erro',
        });
        setErro(error as AxiosError);
      } finally {
        setLoading(false);
      }

      return null;
    },
    [criarMensagem],
  );
  return [login, { loading, erro }];
}
